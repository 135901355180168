import "@/styles/globals.css"
import type { AppProps } from "next/app"
import theme from "../styles/theme"
import GlobalHeaderComponent from "../components/GlobalHeaderComponent"
import MobileGlobalHeaderComponent from "../components/MobileGlobalHeaderComponent"
import * as amplitude from "@amplitude/analytics-browser"
import Script from "next/script"
import FacebookPixel from "../components/FacebookPixel"
import Sidebar from "@/components/Sidebar"
import useSidebarStore from "@/store/SidebarStore"
import { ChakraProvider, Box, Flex } from "@chakra-ui/react"
import { useMediaQuery } from "@chakra-ui/react"
import { NextIntlClientProvider } from "next-intl"
import { useRouter } from "next/router"

export default function App({ Component, pageProps }: AppProps) {
  const [isDesktop] = useMediaQuery("(min-width: 800px)")
  const [isMobile] = useMediaQuery("(max-width: 480px)")
  const GAID = process.env.NEXT_PUBLIC_GA_ID
  const router = useRouter()
  const { pathname } = router
  const isLandingPage = pathname === "/features/keyword"
  const maxWidth = isLandingPage || isMobile ? "100%" : "1100px"
  const minWidth = isDesktop ? "600px" : "100%"
  const { isOpen: isSidebarOpen } = useSidebarStore()

  const routesWithoutSidebar = [
    "/",
    "/signup",
    "/leaderboard",
    "/pricing",
    "/contact",
    "/features/keyword",
    "/features/threads-keyword-auto-reply",
  ]

  const shouldShowSidebar =
    (!routesWithoutSidebar.includes(pathname) && !isMobile) ||
    (isMobile && isSidebarOpen)

  const header = isMobile ? (
    <MobileGlobalHeaderComponent locale={router.locale} />
  ) : (
    <GlobalHeaderComponent locale={router.locale} />
  )

  amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_ID, null, {
    minIdLength: 1,
  })

  return (
    <NextIntlClientProvider
      locale={router.locale}
      timeZone="Asia/Taipei"
      messages={pageProps.messages}>
      <ChakraProvider theme={theme}>
        <FacebookPixel />
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=G-${GAID}`}
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-${GAID}');
          `}
        </Script>

        <Box height="100vh" overflow="hidden">
          {/* Header */}
          <Box
            position="fixed"
            top={0}
            left={0}
            right={0}
            zIndex={20}
            bg="white">
            {header}
          </Box>

          <Box
            position="fixed"
            top="68px"
            left={0}
            right={0}
            bottom={0}
            overflow="hidden">
            <Flex height="100%">
              {shouldShowSidebar && (
                <Box
                  width="250px"
                  height="100%"
                  overflowY="auto"
                  bg="white"
                  borderRight="1px solid"
                  borderColor="gray.200"
                  zIndex={9999}>
                  <Sidebar />
                </Box>
              )}
              <Box flex={1} overflowY="auto">
                <Box
                  flex={1}
                  overflowY="auto"
                  bg="ui.foreground1"
                  maxWidth={maxWidth}
                  minWidth={minWidth}
                  marginX="auto">
                  <Component {...pageProps} />
                </Box>
              </Box>
            </Flex>
          </Box>
        </Box>
      </ChakraProvider>
    </NextIntlClientProvider>
  )
}
