import { create } from 'zustand'
import apiHelper from '@/network/api'
import Cookies from 'js-cookie'

interface Member {
    isVip: boolean
    connectedThreadsAccounts: Array<{ username: string }>
}

interface MemberStore {
    member: Member | null
    customerPortalInfo: any
    selectedAccount: string | null
    setMember: (newValue: Member | null) => void
    setSelectedAccount: (newValue: string | null) => void
    fetchMember: () => Promise<Member | undefined>
    getCustomerPortalLink: () => Promise<any>
    checkIsLoggedIn: () => boolean
    checkIsVip: () => boolean
    getSubscribedVipPlan: () => string | null
    getCurrentSelectedThreadsAccountId: () => string | null
}

const useMemberStore = create<MemberStore>((set, get) => ({
    member: null,
    customerPortalInfo: null,
    selectedAccount: null,
    setMember: (newValue) => set({ member: newValue }),
    setSelectedAccount: (newValue) => set({ selectedAccount: newValue }),
    fetchMember: async () => {
        if (Cookies.get('access_token') == null) {
            return
        }
        const response = await apiHelper(null).get('me')
        const data = response.data
        if (data) {
            set({ member: data })
            const { connectedThreadsAccounts } = data
            if (get().selectedAccount == null && connectedThreadsAccounts?.length > 0) {
                set({ selectedAccount: connectedThreadsAccounts[0].username })
            }
            return data 
        }
    },

    getCustomerPortalLink: async () => {
        if (Cookies.get('access_token') == null) {
            return
        }
        const response = await apiHelper(null).get('me/customerPortal')
        const data = response.data
        if (data) {
            set({ customerPortalInfo: data })
            return data 
        }
    },

    checkIsLoggedIn: () => {
        return Cookies.get('access_token') != null
    },

    checkIsVip: () => {
        const member = get().member
        if (member) { 
            return member['isVip']
        }
        return false 
    },

    getCurrentSelectedThreadsAccountId: () => {
        const member = get().member
        const selectedAccount = get().selectedAccount
        const selectedAccountInfo = member?.connectedThreadsAccounts?.find(
            account => account.username === selectedAccount
        )
        const threadsUserId = selectedAccountInfo?.threadsUserId    
        return threadsUserId
    },

    getSubscribedVipPlan: () => {
        const member = get().member
        if (member) {
            return member['subscribedVipPlan']
        }
        return null
    },
}))

export default useMemberStore
