"use client"

import { Box, VStack, Text, Link, Select, Flex, Divider } from "@chakra-ui/react"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import NextLink from "next/link"
import { useRouter } from "next/router"
import { useState, useEffect } from "react"
import useMemberStore from "@/store/MemberStore"
import Cookies from "js-cookie"
import { useTranslations } from "next-intl"
import {
    Home,
    MessageSquare,
    Search,
    Calendar,
    Instagram,
    Hash,
    Lightbulb,
    BarChart3,
    Settings,
    ChevronDown,
    User,
    Copy,
} from "lucide-react"

export default function Sidebar() {
    const router = useRouter()
    const { locale } = router
    const [account, setAccount] = useState("")
    const [accounts, setAccounts] = useState([])
    const t = useTranslations("Sidebar")
    const tPricing = useTranslations("Pricing")
    const dashboardUrl = `/dashboard/${account ?? ""}`
    const metricsUrl = `/threads/user/posts/${account ?? ""}`
    const menuItems = [
        {
            name: t("Menu.dashboard"),
            path: dashboardUrl,
            matchPath: "/dashboard",
            icon: Home,
        },
        {
            name: t("Menu.postMetrics"),
            path: metricsUrl,
            matchPath: "/threads/user/posts",
            icon: BarChart3,
        },
        {
            name: t("Menu.replyManager"),
            path: "/threads/reply-manager",
            matchPath: "/threads/reply-manager",
            icon: MessageSquare,
        },
        {
            name: t("Menu.keywordMonitoring"),
            path: "/threads/keyword/dashboard",
            matchPath: "/threads/keyword/",
            icon: Search,
        },
        {
            name: t("Menu.schedulePost"),
            path: "/threads/schedule-post",
            matchPath: "/threads/schedule-post",
            icon: Calendar,
        },
        {
            name: t("Menu.contactUs"),
            path: "https://www.instagram.com/threadslytics/",
            matchPath: null,
            icon: Instagram,
        },
    ]

    // New array for tools under the "實用小工具" section
    const toolItems = [
        {
            name: t("Title.multiPost"),
            path: "/threads/multi-post",
            matchPath: "/threads/multi-post",
            icon: Copy,
        },
    ]

    const memberStore = useMemberStore()
    const subscribedVipPlan = memberStore.getSubscribedVipPlan()

    console.log("subscribedVipPlan", subscribedVipPlan)
    const { customerPortalInfo, getCustomerPortalLink } = useMemberStore()
    const handleAccountChange = (e) => {
        const value = e.target.value
        if (value === "connect") {
            router.push("/threads/connect")
            return
        }
        memberStore.setSelectedAccount(value)
        // Check if current path starts with /dashboard/ and redirect if needed
        if (router.pathname.startsWith("/dashboard/")) {
            router.push(`/dashboard/${value}`)
        }
    }

    const handleCustomerPortalLink = () => {
        if (customerPortalInfo && customerPortalInfo.customerPortalLink) {
            const link = customerPortalInfo.customerPortalLink
            router.push(link)
        }
    }

    useEffect(() => {
        if (memberStore.member) {
            const { connectedThreadsAccounts } = memberStore.member
            setAccounts(connectedThreadsAccounts)
            getCustomerPortalLink()
        }
    }, [memberStore.member, getCustomerPortalLink])

    useEffect(() => {
        if (memberStore.selectedAccount) {
            setAccount(memberStore.selectedAccount)
        }
    }, [memberStore.selectedAccount])

    return (
        <Box
            w="280px"
            bgGradient="linear(to-b, #2C2C2C, #252525)"
            color="white"
            h="100vh"
            position="fixed"
            top="68px"
            left={0}
            overflowY="auto"
            boxShadow="0 4px 12px rgba(0, 0, 0, 0.15)"
            borderRight="1px solid rgba(255, 255, 255, 0.1)"
        >
            <Flex direction="column" h="calc(100vh - 68px)">
                <VStack spacing={4} align="stretch" p={5}>
                    {/* Account Selector with improved styling */}
                    <Box bg="rgba(255, 255, 255, 0.05)" borderRadius="md" p={3} border="1px solid rgba(255, 255, 255, 0.1)">
                        <Text fontSize="14px" fontWeight="medium" mb={2} color="rgba(255, 255, 255, 0.7)">
                            {t("Actions.selectAccount")}
                        </Text>
                        <Select
                            height="40px"
                            value={account}
                            bg="rgba(255, 255, 255, 0.08)"
                            color="white"
                            onChange={handleAccountChange}
                            borderColor="rgba(255, 255, 255, 0.2)"
                            _hover={{ borderColor: "rgba(255, 255, 255, 0.3)" }}
                            _focus={{ borderColor: "#3182CE", boxShadow: "0 0 0 1px #3182CE" }}
                            icon={<ChevronDown size={16} />}
                            sx={{
                                "& option": {
                                    bg: "#2C2C2C",
                                    color: "white",
                                },
                            }}
                        >
                            {accounts.map((account) => (
                                <option key={account.username} value={account.username}>
                                    {account.username}
                                </option>
                            ))}
                            <option value="connect">{t("Actions.connectNewAccount")}</option>
                        </Select>
                    </Box>

                    <Box>
                        <Text
                            fontSize="12px"
                            fontWeight="bold"
                            mb={2}
                            color="rgba(255, 255, 255, 0.5)"
                            textTransform="uppercase"
                            letterSpacing="wider"
                        >
                            {t("Title.mainMenu")}
                        </Text>
                        {menuItems.map((item) => (
                            <Link
                                as={NextLink}
                                href={item.path}
                                key={item.path}
                                p={3}
                                mb={1}
                                display="flex"
                                alignItems="center"
                                rounded="md"
                                transition="all 0.2s"
                                _hover={{ bg: "rgba(59, 130, 246, 0.7)", transform: "translateX(3px)" }}
                                bg={
                                    item.matchPath
                                        ? router.pathname.startsWith(item.matchPath)
                                            ? "rgba(59, 130, 246, 0.8)"
                                            : "transparent"
                                        : "transparent"
                                }
                            >
                                <Box
                                    mr={3}
                                    color={router.pathname.startsWith(item.matchPath || "") ? "white" : "rgba(255, 255, 255, 0.7)"}
                                >
                                    <item.icon size={18} />
                                </Box>
                                <Text
                                    fontSize="16px"
                                    fontWeight={router.pathname.startsWith(item.matchPath || "") ? "semibold" : "normal"}
                                    color="white"
                                >
                                    {item.name}
                                </Text>
                            </Link>
                        ))}
                    </Box>

                    <Divider borderColor="rgba(255, 255, 255, 0.1)" my={2} />

                    {/* Tools Section */}
                    <Box>
                        <Text
                            fontSize="12px"
                            fontWeight="bold"
                            mb={2}
                            color="rgba(255, 255, 255, 0.5)"
                            textTransform="uppercase"
                            letterSpacing="wider"
                        >
                            {t("Title.tools")}
                        </Text>

                        {toolItems.map((item) => (
                            <Link
                                as={NextLink}
                                href={item.path}
                                key={item.path}
                                p={3}
                                mb={1}
                                display="flex"
                                alignItems="center"
                                rounded="md"
                                transition="all 0.2s"
                                _hover={{ bg: "rgba(59, 130, 246, 0.7)", transform: "translateX(3px)" }}
                                bg={
                                    item.matchPath
                                        ? router.pathname.startsWith(item.matchPath)
                                            ? "rgba(59, 130, 246, 0.8)"
                                            : "transparent"
                                        : "transparent"
                                }
                            >
                                <Box
                                    mr={3}
                                    color={router.pathname.startsWith(item.matchPath || "") ? "white" : "rgba(255, 255, 255, 0.7)"}
                                >
                                    <item.icon size={18} />
                                </Box>
                                <Text
                                    fontSize="16px"
                                    fontWeight={router.pathname.startsWith(item.matchPath || "") ? "semibold" : "normal"}
                                    color="white"
                                >
                                    {item.name}
                                </Text>
                            </Link>
                        ))}
                    </Box>
                </VStack>

                {/* Settings Section */}
                <Box mt="auto" p={4} borderTop="1px solid rgba(255, 255, 255, 0.1)" bg="rgba(0, 0, 0, 0.2)">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Flex
                                alignItems="center"
                                p={2}
                                rounded="md"
                                cursor="pointer"
                                _hover={{ bg: "rgba(255, 255, 255, 0.1)" }}
                                transition="all 0.2s"
                            >
                                <Settings size={18} className="mr-2" />
                                <Text fontSize="16px" fontWeight="medium" color="white">
                                    {t("Settings.title")}
                                </Text>
                                <Box
                                    ml="auto"
                                    mr={2}
                                    px={2}
                                    py={0.5}
                                    bg={
                                        subscribedVipPlan === "basic" || subscribedVipPlan === "pro"
                                            ? "rgba(59, 130, 246, 0.8)"
                                            : "rgba(255, 255, 255, 0.1)"
                                    }
                                    borderRadius="full"
                                    fontSize="12px"
                                    color="white"
                                >
                                    {subscribedVipPlan == null && tPricing("Title.freePlan")}
                                    {subscribedVipPlan === "basic" && tPricing("Title.personalPlan")}
                                    {subscribedVipPlan === "pro" && tPricing("Title.professionalPlan")}
                                </Box>
                                <ChevronDown size={16} />
                            </Flex>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-56">
                            <DropdownMenuLabel className="flex items-center">
                                <User size={14} className="mr-2" />
                                {t("Settings.account")}
                            </DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={() => router.push("/team/manage")}>
                                {t("Settings.manageTeam")}
                            </DropdownMenuItem>
                            {customerPortalInfo && customerPortalInfo.customerPortalLink && (
                                <DropdownMenuItem onClick={handleCustomerPortalLink}>
                                    {t("Settings.manageSubscription")}
                                </DropdownMenuItem>
                            )}
                            <DropdownMenuItem
                                onClick={() => {
                                    const html = locale == "zh-TW" ? "privacyPolicy.html" : "privacyPolicyEn.html"
                                    window.open(`/${html}`, "_blank")
                                }}
                            >
                                {t("Settings.privacyPolicy")}
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                                onClick={() => {
                                    Cookies.remove("access_token", { path: "/", domain: "threadslytics.com" })
                                    Cookies.remove("access_token", { path: "/", domain: "threadslytics.app" })
                                    Cookies.remove("access_token", { path: "/", domain: "tw.threadslytics.com" })
                                    Cookies.remove("access_token", { path: "/", domain: "tw.threadslytics.app" })
                                    window.location.reload()
                                }}
                                className="text-red-500"
                            >
                                {t("Settings.logout")}
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </Box>
            </Flex>
        </Box>
    )
}

