"use client"

import { Box, Link, IconButton } from "@chakra-ui/react"
import { HamburgerIcon } from "@chakra-ui/icons"
import { useRef } from "react"
import useSidebarStore from "@/store/SidebarStore"

export default function MobileHeaderComponent({ locale }) {
  const { toggle: toggleSidebar } = useSidebarStore()
  const btnRef = useRef()

  return (
    <Box
      height="68px"
      background="#302F2F"
      width="100%"
      display="flex"
      alignItems="center">
      <IconButton
        ref={btnRef}
        onClick={toggleSidebar}
        marginLeft="16px"
        icon={<HamburgerIcon />}
      />
      <Link
        href="/"
        color="main.red"
        marginLeft="16px"
        fontSize="26px"
        fontWeight="bold">
        Threadslytics
      </Link>
    </Box>
  )
}
