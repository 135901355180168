"use client"

import { Box, Link, Center, HStack, Text, Flex } from "@chakra-ui/react"
import { useEffect } from "react"
import { useRouter } from "next/router"
import { useState } from "react"
import * as amplitude from "@amplitude/analytics-browser"
import * as Sentry from "@sentry/nextjs"
import useMemberStore from "@/store/MemberStore"
import dynamic from "next/dynamic"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
    DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu"
import { ChevronDown, Globe, LogIn } from "lucide-react"

const lang = require("@/messages/HeaderComponent.json")
const _ = require("lodash")

// Dynamically import Chakra components to avoid hydration errors
const DynamicButton = dynamic(() => import("@chakra-ui/react").then((mod) => mod.Button), { ssr: false })
const DynamicMenu = dynamic(() => import("@chakra-ui/react").then((mod) => mod.Menu), { ssr: false })
const DynamicMenuButton = dynamic(() => import("@chakra-ui/react").then((mod) => mod.MenuButton), { ssr: false })
const DynamicMenuList = dynamic(() => import("@chakra-ui/react").then((mod) => mod.MenuList), { ssr: false })
const DynamicMenuItem = dynamic(() => import("@chakra-ui/react").then((mod) => mod.MenuItem), { ssr: false })

export default function GlobalHeaderComponent({ locale }) {
    const t = lang[locale]
    const { fetchMember, member } = useMemberStore()
    const isLoggedIn = useMemberStore((state) => state.checkIsLoggedIn())
    const router = useRouter()
    const { asPath } = router
    const [currentLanguage, setCurrentLanguage] = useState("English")
    const contactUsLink = "https://www.instagram.com/threadslytics/"
    const [isClient, setIsClient] = useState(false)

    // Set isClient to true when component mounts to avoid hydration mismatch
    useEffect(() => {
        setIsClient(true)
    }, [])

    if (member) {
        amplitude.setUserId(member.id)
        const identifyEvent = new amplitude.Identify()
        identifyEvent.set("isVIP", member.isVip)
        identifyEvent.set("subscribedVipPlan", member.subscribedVipPlan)
        identifyEvent.set("connectedThreadsAccounts", member.connectedThreadsAccounts)
        amplitude.identify(identifyEvent)

        Sentry.setUser({
            id: member.id,
            email: member.email,
        })
    }

    useEffect(() => {
        const currentDomain = window.location.hostname
        if (_.includes(currentDomain, "tw.")) {
            setCurrentLanguage("正體中文")
        }
        fetchMember()
    }, [fetchMember])

    const handleTaiwanButton = () => {
        const domain = process.env.NEXT_PUBLIC_WEB_DOMAIN_NAME
        const protocol = domain == "localhost:4999" ? "http" : "https"
        const url = `${protocol}://tw.${domain}${asPath}`
        window.location.href = url
    }

    const handleEnglishButton = () => {
        const domain = process.env.NEXT_PUBLIC_WEB_DOMAIN_NAME
        const protocol = domain == "localhost:4999" ? "http" : "https"
        const url = `${protocol}://${domain}${asPath}`
        window.location.href = url
    }

    const navigateToFeature = (path) => {
        router.push(path)
    }

    return (
        <Box
            height="68px"
            bgGradient="linear(to-r, #2C2C2C, #252525)"
            width="100%"
            display="flex"
            alignItems="center"
            boxShadow="0 2px 8px rgba(0, 0, 0, 0.15)"
            borderBottom="1px solid rgba(255, 255, 255, 0.1)"
            position="fixed"
            top="0"
            zIndex="1000"
        >
            {/* Logo Section */}
            <Flex alignItems="center" ml={4} mr={8}>
                <Link href="/" _hover={{ textDecoration: "none", opacity: 0.9 }} display="flex" alignItems="center">
                    <Text
                        fontSize="26px"
                        fontWeight="bold"
                        bgGradient="linear(to-r, #ff4d4d, #ff8080)"
                        bgClip="text"
                        letterSpacing="tight"
                    >
                        Threadslytics
                    </Text>
                </Link>
            </Flex>

            {/* Center Navigation */}
            <Center flex="1">
                <HStack spacing="24px">
                    {/* Features Dropdown - Only render on client side */}
                    {isClient && (
                        <DropdownMenu>
                            <DropdownMenuTrigger>
                                <Box
                                    as="div"
                                    px={4}
                                    py={2}
                                    fontSize="16px"
                                    fontWeight="medium"
                                    color="white"
                                    borderRadius="md"
                                    cursor="pointer"
                                    display="flex"
                                    alignItems="center"
                                    _hover={{ bg: "rgba(255, 255, 255, 0.1)" }}
                                    transition="all 0.2s"
                                >
                                    {t["Title"]["features"]}
                                    <ChevronDown className="h-4 w-4 ml-1 opacity-70" />
                                </Box>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="start" className="bg-[#2C2C2C] border-gray-700 rounded-md shadow-lg">
                                <DropdownMenuItem
                                    onClick={() => navigateToFeature("/features/keyword")}
                                    className="text-white hover:bg-blue-600/80 cursor-pointer transition-colors"
                                >
                                    {t["Title"]["keywordTracking"]}
                                </DropdownMenuItem>
                                <DropdownMenuSeparator className="bg-gray-700" />
                                <DropdownMenuItem
                                    onClick={() => navigateToFeature("/features/threads-keyword-auto-reply")}
                                    className="text-white hover:bg-blue-600/80 cursor-pointer transition-colors"
                                >
                                    {t["Title"]["threadsKeywordAutoReply"]}
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    )}

                    <Link
                        href="/pricing"
                        color="white"
                        fontWeight="medium"
                        fontSize="16px"
                        px={4}
                        py={2}
                        borderRadius="md"
                        _hover={{
                            textDecoration: "none",
                            bg: "rgba(255, 255, 255, 0.1)",
                            transform: "translateY(-1px)",
                            transition: "all 0.2s",
                        }}
                    >
                        {t["Title"]["pricing"]}
                    </Link>

                    <Link
                        href={contactUsLink}
                        color="white"
                        fontWeight="medium"
                        fontSize="16px"
                        isExternal
                        px={4}
                        py={2}
                        borderRadius="md"
                        _hover={{
                            textDecoration: "none",
                            bg: "rgba(255, 255, 255, 0.1)",
                            transform: "translateY(-1px)",
                            transition: "all 0.2s",
                        }}
                    >
                        {t["Title"]["contactUs"]}
                    </Link>
                </HStack>
            </Center>

            {/* Right Side Controls */}
            <Flex alignItems="center" mr={4}>
                {/* Login/Register Button */}
                {!isLoggedIn && isClient && (
                    <DynamicButton
                        ml={4}
                        bgGradient="linear(to-r, #ff4d4d, #ff7070)"
                        color="white"
                        _hover={{
                            bgGradient: "linear(to-r, #ff3d3d, #ff6060)",
                            transform: "translateY(-1px)",
                            boxShadow: "0 4px 8px rgba(255, 77, 77, 0.3)",
                        }}
                        _active={{
                            bgGradient: "linear(to-r, #ff2d2d, #ff5050)",
                            transform: "translateY(0)",
                        }}
                        transition="all 0.2s"
                        borderRadius="md"
                        fontWeight="medium"
                        leftIcon={<Box as={LogIn} size="16px" />}
                        onClick={() => router.push("/signup")}
                    >
                        {t["Action"]["loginOrSignUp"]}
                    </DynamicButton>
                )}
            </Flex>
        </Box>
    )
}

